import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Switch } from "react-router-dom";
import "./App.css";
import ClientLoadingFallback from "./components/Fallbacks/ClientLoadingFallback";
import LogOut from "./components/LogOut";
import AuthenticatedRoute from "./components/Routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Routes/UnauthenticatedRoute";
import SideBar from "./components/SideBar";
import { AuthContext } from "./lib/contextLib";
import { findSpaceByIndex, tryLogin } from "./lib/qlikHelper";
import Login from "./pages/Login/Login";


/**
 * Enum for auth state
 * 
 * 00 - not signed in. not loading auth data.
 * 01 - not signed in.     loading auth data.
 * 10 -     signed in.     loading auth data complete.
 * @readonly
 * @enum {0|1|2}
 */
export const AuthStates = {
  notSignedIn: 0b00, // 0
  loadingData: 0b01, // 1
  signedIn:    0b10  // 2
}

async function getCurrentOrg() {
  return "squares"
  try {
    const space = await findSpaceByIndex(0);
    console.log("Fetched space successfully")
    return space.name;
  } catch (e) {
    console.log("Failed to fetch first space");
  }

  return null;
}

function App() {
  const [authState, setAuthState] = useState(AuthStates.loadingData);
  const [currentOrg, setCurrentOrg] = useState(null);
  const ClientLoader = React.lazy(() => import(/* webpackChunkName: "companyrout" */ "./clients/ClientLoader"));

  async function onPageLoad() {
    try {
      await Auth.currentSession()

      // A user is logged into Cognito and to QLik
      setAuthState(AuthStates.signedIn)
    } catch (e) {
      console.error("[App.js] onLoad: ", e)
      setAuthState(AuthStates.notSignedIn)
      setCurrentOrg(null)
    }
  }

  async function afterSignIn() {
    await tryLogin()
    const currentOrganisation = await getCurrentOrg()
    setCurrentOrg(currentOrganisation)
  }

  useEffect(() => {
    if (authState == AuthStates.signedIn)
      afterSignIn();

    if (authState == AuthStates.loadingData)
      onPageLoad()
  }, [authState])

  useEffect(() => {
    if(currentOrg != null) return;

  }, [currentOrg])

  if (authState == AuthStates.loadingData) return <></>

  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Switch>
          <UnauthenticatedRoute path="/login" component={Login} />
          <AuthenticatedRoute path="/">
            <div className="App">
              {/* <Header /> */}
              {
                (currentOrg != null) &&
                <ErrorBoundary
                  FallbackComponent={ClientLoaderErrorFallback}>
                  <React.Suspense fallback={<p>Loading client loader...</p>}>
                    <ClientLoader customerCode={"squares"} />
                  </React.Suspense>
                </ErrorBoundary>
              }
              {
                (currentOrg == null) &&
                <>
                  <SideBar pages={[]}/>
                  <div style={{padding: "0rem", display: "flex", width: "100%"}}>
                    <ClientLoadingFallback />
                  </div>
                </>
              }
            </div>
          </AuthenticatedRoute>
        </Switch>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

/**
 * A fallback function that is used when the client loader fails.
 * The client loader can fail if the current user has a customer code that does not exist in the routes table.
 * @param {{error: {message: string, stack: string}, resetErrorBoundary: () => void}} props 
 * @returns 
 */
function ClientLoaderErrorFallback(props) {
  return <div style={{ padding: "1rem" }}>
    <h1>Error: An error occured when loading client environment</h1>
    <h3>Please contact an administrator as your account may not have access to your environment.</h3>

    {/* Show the current time */ new Date().toISOString()}
    <div style={{ background: "#B00544", padding: 5, borderRadius: "6px" }}>
      <p>Message: {props.error.message}</p>
      <p>Stack Trace: {props.error.stack}</p>
    </div>

    <div>
      <button onClick={props.resetErrorBoundary}>Try again</button>
      <div style={{ width: "10rem" }}><LogOut /></div>
    </div>
  </div>
}

export default App;