import React, { useState, useEffect } from 'react';
import "./ClientLoadingFallback.scss"

export default function ClientLoadingFallback() {
  const messages = [
    "Securing your environment...", "Loading client information...",
    "Establishing new secure connection...", "Authenticating client session...",
    "Validating company access...", "Accessing role policy...",
    "Validating security tokens...", "Collecting application state...",
    "Dynamically fetching chunks...", "Collecting segmented environment chunks...",
    "Evaluating dependencies..."
  ]
  const [currentMessage, setCurrentMessage] = useState("")

  useEffect(() => {
    // Change the message periodically
    const intervalID = setInterval(() => {
      setCurrentMessage(messages[Math.floor(Math.random() * messages.length)])
    }, 1500)

    return () => {
      clearInterval(intervalID)
    }
  }, [])

  return <div className='clientLoadingFallback'>
    <div className="clientLoadingFallback-animationContainer">
      {
        [1, 2, 3, 4, 5].map((e, index) =>
          <div className='clientLoadingFallback-animationContainer-growingBlock' style={{animationDelay: `${index * 100}ms`}} />
        )
      }
    </div>
    <div>
      <p>{currentMessage}</p>
    </div>

  </div>
}