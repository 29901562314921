import { Auth } from 'aws-amplify';
import { getSpaces, getApps, getBookmarks, isLoggedIn, getSignedQLikJWT, loginToQlikWithToken, getQlikOverview, getQixAPI } from "./qlikLib";
import './qlikAPI_typedef';

// Helper method to check if two strings are equal when case-sensitivity is disabled
const caseInsensitiveEqual = (a, b) => a.toLowerCase().includes(b.toLowerCase())

export async function tryLogin() {
  const loggedIn = await isLoggedIn();

  // If the user is not logged in, try to establish a session
  if (loggedIn === false) {
    try {
      let cognitoUser = await Auth.currentSession()

      // If not, generate a token and use it to login to QLik
      const token = await getSignedQLikJWT(cognitoUser)
      const authResponse = await loginToQlikWithToken(token)

      if (authResponse.ok === false) {
        throw new Error("Failed to login to QLik with JWT: " + token)
      }
    } catch (e) {
      console.error("[QlikFrame.jsx] Error when attemping to log into QLik: ", e)
      // Exit the function
      return;
    }
  }
}


/**
 * Get the i-th space in a tenant that the user has access to
 * @param {number} index The index of a space
 * @returns {Promise<string>} A space id
 */
export async function getSpaceId(index) {
  try {
    const response = await getSpaces()

    if (response.length == 0)
      throw new Error("No spaces found");

    return response[index].id;

  } catch (e) {
    throw new Error('Could not fetch space Id. Error: ' + e);
  }
}

/**
 * Find an app in a space by its name
 * @param {string} spaceId The space to search
 * @param {string} name The name of an app
 * @returns {Promise<string>} An app id
 */
export async function getAppId(spaceId, name) {
  try {
    const response = await getApps(spaceId)
    name = name.trim() // Clean the input

    if (response.length == 0)
      throw new Error("No apps found");

    const app = response.find(app => app.name.includes(name))
    return app.id;
  } catch (e) {
    throw new Error('Could not fetch app Id. Error: ' + e);
  }
}

/**
 * Find a sheet in an app by its bookmark name
 * @param {string} appId A valid Qlik app id
 * @param {string} name The name of a bookmark
 * @returns  {Promise<string>} A sheet id
 */
export async function getSheetId(appId, name) {
  const bookmarks = await getBookmarks(appId);
  name = name.trim() // Clean the input

  if (bookmarks.length != 0) {
    return bookmarks.find(sheet => sheet.title.trim().includes(name)).sheetId;
  }

  throw new Error("Could not find sheet Id")
}

/**
 * Find a space by name.
 * @param {string} spaceName The name of a space.
 * @returns {Promise<QlikAPI_Space>} The space if found. Otherwise undefined.
 */
export async function findSpaceByName(spaceName, spaces = null) {
  const obj = spaces || await getQlikOverview();
  let result = obj.find(space => caseInsensitiveEqual(space.name, spaceName));
  if (result == undefined) throw new Error(`No space with the name '${spaceName}' was found.`);
  return result;
}

/**
 * Find a space by index.
 * @param {string} spaceName The name of a space.
 * @returns {Promise<QlikAPI_Space>} The space if found. Otherwise undefined.
 */
export async function findSpaceByIndex(index) {
  const obj = await getQlikOverview();
  if (index < 0 && obj.length <= index) throw new Error(`Index ${index} is out of bounds.`);
  return obj[index];
}

/**
 * Find an app by name in a space.
 * @param {QlikAPI_Space} space 
 * @param {string} appName 
 * @returns {QlikAPI_App} The app if found. Otherwise undefined.
 */
export function space_findAppByName(space, appName) {
  if (space instanceof Promise) {
    throw new Error("`space` is a Promise. Did you forget to `await`?")
  }
  appName = appName.trim();
  let result = space.apps.find(app => caseInsensitiveEqual(app.name, appName))
  if (result == undefined) throw new Error(`No app with the name '${appName}' was found in the space ${space.name}.`);
  return result;
}

/**
 * Find a sheet by name in an app.
 * @param {QlikAPI_App} app The app to search.
 * @param {string} sheetName The sheet name to find.
 * @returns {QlikAPI_Sheet} The sheet if found. Otherwise undefined.
 */
export function app_findSheetByName(app, sheetName) {
  sheetName = sheetName.trim();
  let result = app.sheets.find(sheet => caseInsensitiveEqual(sheet.name, sheetName));
  if (result == undefined) throw new Error(`No sheet with the name '${sheetName}' was found in the app ${app.name}.`);
  return result;
}

/**
 * Find an object by name in a sheet.
 * @param {QlikAPI_App} app The parent app of the sheet.
 * @param {QlikAPI_Sheet} sheet The sheet to search.
 * @param {string} objectTitle The object title to find.
 * @returns {Promise<string>} The object id if found. Otherwise undefined.
 */
export async function sheet_findObjectIdByTitle(app, sheet, objectTitle) {
  const api = await getQixAPI(app.id);

  for (let i = 0; i < sheet.objects.length; i++) {
    // Get the object reference
    let obj = await api.getObject(sheet.objects[i]);

    // Get the object properties
    let objProperties = await obj.getProperties();
    let title = objProperties.title;

    // Skip objects with no title
    if (title == "") continue;

    // A title can be a string or an expression
    if (title.qStringExpression != undefined) title = title.qStringExpression.qExpr;
    if (caseInsensitiveEqual(title, objectTitle)) return objProperties.qInfo.qId;
  }

  throw new Error(`No object with the title '${objectTitle}' was found in the sheet ${sheet.name}.`);
}

/**
 * Find an object by label in a sheet.
 * @param {QlikAPI_App} app The parent app of the sheet.
 * @param {QlikAPI_Sheet} sheet The sheet to search.
 * @param {string} label The object expression to find.
 * @returns {Promise<string>} The object id if found. Otherwise undefined.
 */
export async function sheet_findObjectIdByLabel(app, sheet, label) {
  const api = await getQixAPI(app.id);

  for (let i = 0; i < sheet.objects.length; i++) {
    // Get the object reference
    let obj = await api.getObject(sheet.objects[i]);

    // Get the object properties
    let objProperties = await obj.getProperties();
    if (objProperties.qHyperCubeDef && objProperties.qHyperCubeDef.qMeasures) {
      let qMeasures = objProperties.qHyperCubeDef.qMeasures;
      for (let j = 0; j < qMeasures.length; j++) {
        let qDef = qMeasures[j].qDef.qLabel;
        if (!qDef) {
          qDef = qMeasures[j].qDef.qLabelExpression;
        }
        if (caseInsensitiveEqual(qDef, label)) return objProperties.qInfo.qId;
      }
    }
  }

  throw new Error(`No object with the expression '${label}' was found in the sheet ${sheet.name}.`);
}

/**
 * Find an object by label in a sheet.
 * @param {QlikAPI_App} app The parent app of the sheet.
 * @param {QlikAPI_Sheet} sheet The sheet to search.
 * @param {string} def The object expression to find.
 * @returns {Promise<string>} The object id if found. Otherwise undefined.
 */
export async function sheet_findObjectIdByDef(app, sheet, def) {
  const api = await getQixAPI(app.id);

  for (let i = 0; i < sheet.objects.length; i++) {
    // Get the object reference
    let obj = await api.getObject(sheet.objects[i]);

    // Get the object properties
    let objProperties = await obj.getProperties();
    if (objProperties.qHyperCubeDef && objProperties.qHyperCubeDef.qMeasures) {
      let qMeasures = objProperties.qHyperCubeDef.qMeasures;
      for (let j = 0; j < qMeasures.length; j++) {
        let qDef = qMeasures[j].qDef.qDef;
        if (!qDef) {
          throw new Error(`!qDef is undefined`); 
        }
        if (caseInsensitiveEqual(qDef, def)) return objProperties.qInfo.qId;
      }
    }
  }
  throw new Error(`No object with the expression '${def}' was found in the sheet ${sheet.name}.`);
}