import "./SideBar.scss";
import Logo from "../pages/Login/square.png";
import alertImg from "../assets/Vectorbox.png";
import recImg from "../assets/Rectangle.svg";
import LogOut from "./LogOut.jsx";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getQixAPI } from "../lib/qlikLib";
import { getSpaceId, getAppId, tryLogin, findSpaceByIndex, space_findAppByName } from "../lib/qlikHelper";
import { useLocation } from "react-router-dom"

/**
 * @typedef RouteInfo
 * @property {string} name - The name of the page. This name is also used when testing for sheets in a qlik app.
 * @property {string} route - The route for navigation.
 * @property {string | undefined} type - The type of the route object.
 */

/**
 * @param {{pages: RouteInfo[] } param0
 */
function SideBar({ pages }) {
  const [displayedPages, setDisplayedPages] = useState(pages)

  useEffect(() => {
    async function setup() {
      await tryLogin();

      const space = await findSpaceByIndex(0);
      const app = space_findAppByName(space, "Primary");
      const api = await getQixAPI(app.id);

      // Get all objects in the app
      let objects = await api.getAllInfos();

      // Get all the sheet names in the app
      let sheets = await Promise.all(objects.filter(obj => obj.qType == 'sheet').map(async (obj) => {
        let objRef = await api.getObject(obj.qId);
        let { qInfo, qMetaDef } = await objRef.getProperties();
        return qMetaDef.title;
      }))

      // Make pages accessible only if there is a sheet with a matching name
      let newPages = pages.filter(page => sheets.find(sheetName => sheetName.toLowerCase().includes(page.name.toLowerCase())));
      setDisplayedPages(newPages);
    }

    // setup();
  }, [])

  const route = useLocation()
  console.log(route)
  return (
    <>
      <div className="sidebar-component">
        <img className="sidebar-logo" src={Logo} alt="Symboticware" style={{ filter: "invert(1)" }} />
        <h1 style={{ fontWeight: '200', fontFamily: 'Russo One', marginTop: "0.2rem" }}>Squares</h1>
        <div className="sidebar-section">
          {
            // Render links for the sidebar
            displayedPages.map(page => {
              if (page.type == "category") {
                return <div className="sidebar_category">
                  <img className="sidebar-img" src={`${page.img}`} />
                  <h1>{page.name}</h1>
                </div>
              }
              return <Link
                className={`sidebar_p1 ${route.pathname == page.route ? 'selected' : ''}`}
                to={page.route}
                key={`${page.name}:${page.route}`}>
                <img className="sidebar-img" src={`${page.img}`} />
                {page.name}
              </Link>
            })
          }
          <LogOut />
        </div>
      </div>
    </>
  );
}

export default SideBar;
