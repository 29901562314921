import "./LogOut.scss";
import { Auth } from "aws-amplify";
import { useAuthContext } from "../lib/contextLib";
import { useHistory } from "react-router-dom";
import { logout as qlikLogout } from "../lib/qlikLib";
import { AuthStates } from "../App";

function LogOut() {
  const { setAuthState } = useAuthContext();
  const history = useHistory();

  async function onLogout() {
    await Auth.signOut();
    setAuthState(AuthStates.loadingData);
    await qlikLogout()
    window.location.reload()
  }

  return (
    <>
      <button
        className="logout-button"
        onClick={onLogout}
        type="submit"
      >
        Log Out
      </button>
    </>
  );
}

export default LogOut;
